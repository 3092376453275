.app-form-item {
	// background-color: rgba($light-blue-2-color, 1);
	border: 1px solid $light-blue-color;
	border-radius: 4px;
	outline: none;
	box-shadow: 0 0 2px rgba($color: $black-color, $alpha: 0.16);

	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: none;
		border-radius: 4px;
		outline: none;
		box-shadow: none;
	}
}
.ant-input-affix-wrapper {
	background-color: #fff !important;
}
.data-margen {
	margin-top: 50px !important;
}
.success-row {
	color: #34a853 !important;
}
.failure-row {
	color: red !important;
}
.ant-input-affix-wrapper {
	// background-color: rgba($light-blue-2-color, 1);
	border: 1px solid $light-blue-color;
	border-radius: 4px;
	outline: none;
	box-shadow: 0 0 2px rgba($color: $black-color, $alpha: 0.16);
}

.ant-input-affix-wrapper > input.ant-input {
	// background-color: rgba($light-blue-2-color, 1);
	outline: none;

	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		// background-color: rgba($light-blue-2-color, 1);
		border: none;
		border-radius: 4px;
		outline: none;
		box-shadow: none;
	}
}

.app-input-item {
	&.ant-input {
		// background-color: rgba($light-blue-2-color, 1);
		border: 1px solid $light-blue-color;
		border-radius: 1px;
		outline: none;
		box-shadow: 0 0 2px rgba($color: $black-color, $alpha: 0.16);
	}
}

.ant-form-item {
	margin-bottom: 5px;
}

.material-select-img-item {
	position: absolute;
	bottom: 0;
	top: 10px;
}

.material-select-item {
	background-color: transparent;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	box-shadow: none;
	border-radius: 0;
	border-bottom-width: 3px;

	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border-left: 0;
		border-right: 0;
		border-top: 0;
		box-shadow: none;
		border-radius: 0;
		border-bottom-width: 3px;
		padding-left: 30px;
	}
}

.ant-form-item-control-input-content {
	display: flex;
	justify-content: flex-end;
}
.active-payment {
	color: rgb(33, 69, 217);
	// font-weight: 500 !important;
	font-size: 17px !important;
	line-height: 20px;
}
.check-item-role {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	margin-left: 0px;
	margin-right: 0px;
}

.row-class {
	width: 100%;
	//	height: 700px !important;
	padding: 30px !important;
}
.row-p{
	width: 100%;
	padding-bottom: 10px !important;
}
.row-class2 {
	width: 100%;
	height: 300px !important;
	padding: 30px !important;
}
.column-class {
	width: 100%;
	//	height: 700px !important;
	padding: 30px !important;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.border-table {
	border: "1px solid #EBEBEB" !important;
	padding: "40px" !important;
	border-radius: "8px" !important;
}
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button.fc-button-active {
	color: #616161 !important ;
	background: #ffffff !important;
	box-shadow: 3px 3px 10px rgba(188, 188, 188, 0.12),
		-4px -4px 4px rgba(188, 188, 188, 0.12) !important;
	// border-radius: 6px !important;
}

.red-color {
	color: #00b1ff;
}
.login-color {
	color: #0371ba;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	align-items: center;
	text-align: center;
	margin-left: 165px;
}
.padding-forget-password {
	padding-inline-start: 225px;
}
.padding-signup {
	padding-inline-start: 10px;
}
.full-width-select {
	width: 100%;
}
.button-card44 {
	display: flex !important;
	justify-content: flex-end !important;
}
.data-confirm {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-inline-start: 5px;
}

.ant-picker {
	width: 100%;
	background-color: #f1f9ff;
	border: 1px solid #bce0fd;
}

.profile-form-item {
	.ant-row.ant-form-item {
		margin-left: 0;
		margin-right: 0;
	}
}

.gx-width-50 {
	.ant-checkbox-wrapper {
		margin-bottom: 10px;
	}
}
.ant-form-horizontal .ant-row {
	margin-left: 2px;
}
.ant-form-item-control-input-content {
	justify-content: flex-start;
}
.ant-upload.ant-upload-select-picture-card {
	width: 144px;
	height: 146px;
}
.ant-picker {
	background-color: #fff;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
	margin-left: 0.7em;
	background: #f5f5f5 !important;
	// border-radius: 6px !important;
	color: #616161 !important ;
}

.ant-radio-group {
	width: 393px;
}
.ant-checkbox-inner {
	border-radius: 0px !important;
}
.ant-checkbox-wrapper-checked span {
	color: black;
}
